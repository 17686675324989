<template>
  <v-dialog v-model="editDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="info">mdi-pencil</v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center card-title__heading">
        <h2>{{ $t('edit') }} {{ recordData.name }}</h2>
      </v-card-title>
      <v-card-text>
        <v-text-field :label="$t('name')" v-model="editGrade.name" outlined dense color="#757575"
          type="text"></v-text-field>
        <v-text-field :label="$t('payment value')" v-model="editGrade.price" outlined dense color="#757575"
          type="number"></v-text-field>
        <v-autocomplete v-model="editGrade.term" outlined dense :items="terms" :label="$t('terms')" item-text="name"
          item-value="id" return-object></v-autocomplete>
        <v-autocomplete v-model="editGrade.grade_type" outlined dense :items="gradeTypes" :label="$t('terms type')"
          item-text="name" item-value="id" return-object></v-autocomplete>
          <v-autocomplete :loading="childrensLoading" v-model="editGrade.invoice_template" outlined dense :items="childrens"
          :label="$t('invoice type')" item-text="name" item-value="id" :search-input.sync="invoicesTemplateSearch" return-object></v-autocomplete>
                
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn :disabled="disableEditBtn" color="green" class="white--text" depressed @click="editRecord"
          :loading="editBtnLoading">{{ $t('edit') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      invoicesTemplateSearch:null,
      childrens: [],
      childrensLoading: false,
      editGrade: {
        name: "",
        term: null,
        grade_type: null,
        price: null,
        invoice_template:[],
        invoice_template_id: null,
      },
      editBtnLoading: false,
      editDialog: false,
    };
  },
  props: {
    recordData: Object,
  },
  watch: {
  
        async invoicesTemplateSearch(val) {
            this.childrensLoading = true;
            if (val != '' && val != null) {
                try {
                    const searchResult = await axios.get("/inventories/invoice-template/auto-complete", {
                        params: {
                            name: val,
                        },
                    });
                    this.childrens.push(...searchResult.data.data);
                } catch (err) {
                    console.log("err", err);
                } finally {
                    this.childrensLoading = false;
                }
            } else {
                this.childrens = []
            }

        },
  
    editDialog() {
      this.editGrade.name = this.recordData.name;
      this.editGrade.term = this.recordData.term;
      this.editGrade.grade_type = this.recordData.grade_type;
      this.editGrade.price = this.recordData.price;
      this.editGrade.invoice_template_id = this.recordData.invoice_template.id;
      this.editGrade.invoice_template.push(this.recordData.invoice_template);
    },
  },
  methods: {
    async editRecord() {
      try {
        this.editBtnLoading = true;
        await axios.put(`/grade/${this.recordData.id}`, {
          name: this.editGrade.name,
          term_id: this.editGrade.term.id,
          grade_type_id: this.editGrade.grade_type.id,
          price: this.editGrade.price,
          invoice_template_id: this.editGrade.invoice_template.id,
          ...this.invoice_template,
          
        });
        this.$Notifications(
          this.$t('edit success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.$emit("recordUpdated");
        this.editDialog = false;
      } catch (err) {
      } finally {
        this.editBtnLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      terms: "terms/getTerms",
      gradeTypes: "gradeType/getGradeTypes",
    }),
    disableEditBtn() {
      if (!this.editGrade.name) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    
    this.editGrade.name = this.recordData.name;
    this.editGrade.term = this.recordData.term;
    this.editGrade.grade_type = this.recordData.grade_type;
    this.editGrade.price = this.recordData.price;
    this.editGrade.invoice_template_id = this.recordData.invoice_template_id;
    this.editGrade.invoice_template.push(this.recordData.invoice_template);
  },
};
</script>

<style>

</style>
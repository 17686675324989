<template>
  <v-container>
    <v-card elevation="0" class="pt-5">
      <!--  -->
      <v-container>
        <v-row>
          <v-col md="2" class="mr-2" v-if="returnAbility('grade:store')">
            <v-dialog v-model="newGradeDialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" color="green" class="white--text">
                  <v-icon size="20">mdi-plus</v-icon>
                  {{ $t('add class') }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="d-flex justify-center card-title__heading">
                  <h2> {{ $t('add class') }}</h2>
                </v-card-title>
                <v-card-text>
                  <v-text-field :label="$t('name')" v-model="newGrade.name" outlined dense color="#757575"
                    type="text"></v-text-field>
                  <v-text-field :label="$t('payment value')" v-model="newGrade.price" outlined dense color="#757575"
                    type="number"></v-text-field>
                  <v-autocomplete v-model="newGrade.term_id" outlined dense :items="terms" :label="$t('terms')"
                    item-text="name" item-value="id"></v-autocomplete>
                  <v-autocomplete v-model="newGrade.grade_type_id" outlined dense :items="gradeTypes"
                    :label="$t('class type')" item-text="name" item-value="id"></v-autocomplete>
                    <v-autocomplete :loading="childrensLoading" v-model="newGrade.invoice_template_id" outlined dense :items="childrens"
                    :label="$t('invoice type')" item-text="name" item-value="id" :search-input.sync="invoicesTemplateSearch"></v-autocomplete>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn color="green" class="white--text" @click="submitNewGrade" :loading="submitBtnLoading"
                    :disabled="disableSubmitBtn">{{ $t('submit') }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col md="3">
            <v-text-field v-model="nameSearch" :label="$t('search by name')" dense outlined hide-details></v-text-field>
          </v-col>
          <v-col md="3">
            <v-autocomplete hide-details v-model="searchTermId" outlined dense :items="terms"
              :label="$t('search by term')" item-text="name" item-value="id" clearable></v-autocomplete>
          </v-col>
          <v-col md="3" class="d-flex justify-center align-center">
            <v-autocomplete hide-details v-model="searchGradeTypeId" outlined dense :items="gradeTypes"
              :label="$t('search by term type')" item-text="name" item-value="id" clearable></v-autocomplete>
            <v-icon class="mr-2" @click="fetchData(1)">mdi-magnify</v-icon>
          </v-col>
        </v-row>
      </v-container>
      <!--  -->
      <app-base-table :tableOptions="tableOptions" @re-fetch-data="fetchData"
        @re-fetch-paginated-data="fetchData($event)" :enableDelete="false">
        <template slot="editFeature" slot-scope="{ row }">
          <v-icon v-if="returnAbility('grade:show')" color="green"
            @click="$router.push(`grade/info/${row.id}`)">mdi-eye</v-icon>
          <app-edit-grade-modal v-if="returnAbility('grade:update')" @recordUpdated="fetchData"
            :recordData="row"></app-edit-grade-modal>
        </template>
      </app-base-table>
    </v-card>
  </v-container>
</template>

<script>
import editGradeModal from "./editGradeModal.vue";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    appEditGradeModal: editGradeModal,
  },
  data() {
    return {
      invoicesTemplateSearch:null,
      childrens: [],
      childrensLoading: false,
      nameSearch: "",
      searchTermId: null,
      searchGradeTypeId: null,
      newGradeDialog: false,
      submitBtnLoading: false,
      newGrade: {
        invoice_template : [],
        invoice_template_id:null,
        name: "",
        term_id: null,
        grade_type_id: null,
        price: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      terms: "terms/getTerms",
      gradeTypes: "gradeType/getGradeTypes",
      tableOptions: "grade/getTableOptions",
    }),
    disableSubmitBtn() {
      if (
        !this.newGrade.name ||
        !this.newGrade.term_id ||
        !this.newGrade.grade_type_id
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchTerms: "terms/fetchAllTerms",
      fetchGradeTypes: "gradeType/fetchAllGradeTypes",
      fetchGrades: "grade/fetchGrades",
    }),
    async submitNewGrade() {
      try {
        this.submitBtnLoading = true;
        const response = await axios.post("/grade", this.newGrade);
        console.log("submitted?", response);
        this.$Notifications(
          this.$t('add success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData();
        this.newGradeDialog = false;
      } catch (err) {
      } finally {
        this.submitBtnLoading = false;
      }
    },
    fetchData(paginationValue) {
      this.fetchGrades({
        paginationValue,
        nameSearch: this.nameSearch,
        searchTermId: this.searchTermId,
        searchGradeTypeId: this.searchGradeTypeId,
      });
    },
    
  },
  created() {

    this.fetchTerms();
    this.fetchGradeTypes();
    this.fetchData(1);


  },
  watch:{
    
        async invoicesTemplateSearch(val) {
            this.childrensLoading = true;
            if (val != '' && val != null) {
                try {
                    const searchResult = await axios.get("/inventories/invoice-template/auto-complete", {
                        params: {
                            name: val,
                        },
                    });
                    this.childrens.push(...searchResult.data.data);
                } catch (err) {
                    console.log("err", err);
                } finally {
                    this.childrensLoading = false;
                }
            } else {
                this.childrens = []
            }

        },
  }
};
</script>

<style>

</style>